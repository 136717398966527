import {
  sectionContainer,
  sectionContent,
  sectionHeading,
  sectionSubHeading,
} from '../page.css.ts';
import {
  carouselContainer,
  carouselDots,
  carouselItem,
  carouselItemContainer,
  carouselTriggerItem,
  carouselTriggersContainer,
  discoverContent,
} from './discover.css.ts';
import { TrophyIcon } from '@synoptic/ui-kit/icons/react/trophy.tsx';
import { BrightStarIcon } from '@synoptic/ui-kit/icons/react/bright-star.tsx';
import { InsightsIcon } from '@synoptic/ui-kit/icons/react/insights.tsx';
import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
} from '@synoptic/ui-kit/carousel/carousel.tsx';
import { CarouselTrigger } from '@synoptic/ui-kit/carousel/carousel-trigger.tsx';
import { useIsLaptop } from '@synoptic/ui-kit/responsive/hooks.ts';
import carouselTournaments from './media/tournaments.png';
import carouselSocial from './media/social.png';
import carouselDataStream from './media/data-stream.png';
import { CarouselTriggerContent } from './carousel-trigger-content.tsx';

export const Discover = () => {
  const isLaptop = useIsLaptop();

  return (
    <div className={sectionContainer}>
      <div>
        <h1 className={sectionHeading}>Discover Synoptic’s core features</h1>
        <p className={sectionSubHeading}>
          Simple, powerful, and designed to help you excel
        </p>
      </div>
      <div className={sectionContent}>
        <Carousel className={discoverContent} autoplay>
          <div className={carouselContainer}>
            <div className={isLaptop ? carouselItemContainer : undefined}>
              <CarouselContent>
                <CarouselItem className={carouselItem}>
                  <img
                    src={carouselDataStream}
                    alt={'Data Streams'}
                    className={!isLaptop ? carouselItemContainer : undefined}
                    loading="lazy"
                  />
                  {!isLaptop ? (
                    <div>
                      <DataStreamContent />
                    </div>
                  ) : null}
                </CarouselItem>
                <CarouselItem className={carouselItem}>
                  <img
                    src={carouselTournaments}
                    alt={'Tournaments'}
                    className={!isLaptop ? carouselItemContainer : undefined}
                    loading="lazy"
                  />
                  {!isLaptop ? (
                    <div>
                      <TournamentsContent />
                    </div>
                  ) : null}
                </CarouselItem>
                <CarouselItem className={carouselItem}>
                  <img
                    src={carouselSocial}
                    alt={'Social network'}
                    className={!isLaptop ? carouselItemContainer : undefined}
                    loading="lazy"
                  />
                  {!isLaptop ? (
                    <div>
                      <SocialContent />
                    </div>
                  ) : null}
                </CarouselItem>
              </CarouselContent>
            </div>
            <CarouselDots className={carouselDots} />
          </div>
          {isLaptop ? (
            <div className={carouselTriggersContainer}>
              <CarouselTrigger index={0} className={carouselTriggerItem}>
                <DataStreamContent />
              </CarouselTrigger>
              <CarouselTrigger index={1} className={carouselTriggerItem}>
                <TournamentsContent />
              </CarouselTrigger>
              <CarouselTrigger index={2} className={carouselTriggerItem}>
                <SocialContent />
              </CarouselTrigger>
            </div>
          ) : null}
        </Carousel>
      </div>
    </div>
  );
};

const TournamentsContent = () => (
  <CarouselTriggerContent icon={<TrophyIcon />} title={'Tournaments'}>
    <li>
      Compete in time-sensitive challenges on breaking news, geopolitical
      events, and market trends.
    </li>
    <li>Win monetary awards for top performances.</li>
    <li>
      Demonstrate expertise under pressure and gain recognition in your field.
    </li>
  </CarouselTriggerContent>
);

const DataStreamContent = () => (
  <CarouselTriggerContent
    icon={<BrightStarIcon />}
    title={'Data Streams Marketplace'}
  >
    <li>
      Earn money by creating and selling data streams tailored to specific
      topics or industries.
    </li>
    <li>Build a subscriber base for your specialized knowledge.</li>
    <li>
      Access live data from other streams to enhance research and stay ahead of
      trends.
    </li>
  </CarouselTriggerContent>
);

const SocialContent = () => (
  <CarouselTriggerContent icon={<InsightsIcon />} title={'Social network'}>
    <li>Create a profile that shows your skills and achievements.</li>
    <li>Gain followers based on your contributions, not hype.</li>
    <li>Discuss ideas with other experts in your field.</li>
    <li>
      Build trust through our reputation system that rewards quality over
      quantity.
    </li>
  </CarouselTriggerContent>
);
