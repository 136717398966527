import { WaitlistCard } from '../waitlist-card/waitlist-card.tsx';
import { sectionContainer } from '../page.css.ts';
import {
  subWaitlistContent,
  subWaitlistForm,
  subWaitlistLabel,
  subWaitlistTitle,
} from './sub-waitlist.css.ts';
import {
  cardBody,
  cardHeading,
  centeredCardLabel,
} from '../waitlist-card/waitlist-card.css.ts';
import { clsx } from 'clsx';
import { WaitlistForm } from '../waitlist-card/form.tsx';
import { WITH_WAITLIST } from '@/env.ts';
import { LoginEmailForm } from '../waitlist-card/landing-email-form.tsx';

export const SubWaitlist = () => {
  return (
    <div className={sectionContainer}>
      <WaitlistCard className={subWaitlistContent}>
        {WITH_WAITLIST ? (
          <>
            <h1
              className={clsx(cardHeading, subWaitlistTitle, centeredCardLabel)}
            >
              Become an early adopter of Synoptic
            </h1>
            <p className={clsx(cardBody, subWaitlistLabel, centeredCardLabel)}>
              Early adopters get exclusive benefits and a head start in building
              their reputation.
              <br />
              Spaces are limited, so act fast!
            </p>

            <WaitlistForm className={subWaitlistForm} />
          </>
        ) : (
          <>
            <h1
              className={clsx(cardHeading, subWaitlistTitle, centeredCardLabel)}
            >
              Become a member
            </h1>
            <p className={clsx(cardBody, subWaitlistLabel, centeredCardLabel)}>
              Sign up in seconds and be part of a growing community
            </p>
            <LoginEmailForm className={subWaitlistForm} />
          </>
        )}
      </WaitlistCard>
    </div>
  );
};
