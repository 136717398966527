import { ScrollToExplore } from '@/components/scroll-to-explore/scroll-to-explore.tsx';
import { WITH_WAITLIST } from '@/env.ts';
import { paths } from '@/routes-utils/paths.ts';
import { Outlet, useNavigate } from 'react-router';
import { Button } from '@synoptic/ui-kit/button/button.js';
import { WaitlistForm } from '../waitlist-card/form.tsx';
import {
  cardBody,
  cardHeading,
  signUpBtn,
  signUpHelpText,
} from '../waitlist-card/waitlist-card.css.ts';
import { WaitlistCard } from '../waitlist-card/waitlist-card.tsx';
import { Brand } from './brand.tsx';
import { Header } from './header.tsx';
import { welcomeContainer } from './main-waitlist.css.ts';

export const MainWaitlist = () => {
  const navigate = useNavigate();

  return (
    <div className={welcomeContainer}>
      <Header />
      <Brand>
        <ScrollToExplore />
      </Brand>
      <WaitlistCard>
        {WITH_WAITLIST ? (
          <>
            <h1 className={cardHeading}>Join the waitlist</h1>
            <p className={cardBody}>
              Stay tuned, we&apos;ll let you know as soon as Synoptic is
              available
            </p>
            <WaitlistForm />
            <Button
              onClick={() => navigate(paths.signup)}
              size="large"
              className={signUpBtn}
              variant="secondary"
            >
              Sign Up
            </Button>
            <p className={signUpHelpText}>If you already have an invite</p>
          </>
        ) : (
          <Outlet />
        )}
      </WaitlistCard>
    </div>
  );
};
